.navbar {
  align-items: center;
  background-color: var(--color-1);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.622);
  color: var(--color-3);
  display: flex;
  height: 60px;
  justify-content: space-between;
  padding: 0 15px;
  position: relative;
  z-index: 1;
}

/* .navbar a {
  color: var(--color-3);
  text-decoration: none;
} */

.navbar-buttons {
  align-items: center;
  display: flex;
  height: 100%;
}

.navbar-customer {
  display: flex;
  height: 100%;
  padding: 0 15px;
}

.navbar-customer a {
  align-items: center;
  color: white;
  display: flex;
  height: 100%;
  justify-content: center;
  text-decoration: none;
  transition: 0.3s;
  width: 150px;
}

.navbar-customer a:hover {

  /* box-shadow: 0 1px 10px var(--color-3); */
  background-color: var(--color-3);
  color: var(--color-1);
}


.navbar-meus-pedidos:hover {

  /* box-shadow: 0 1px 10px var(--color-3); */
  background-color: var(--color-3);
  color: var(--color-1);
}

.navbar-name {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;

}

/* .navbar-logo {
  height: 30px;
} */


.navbar-logout {
  background-color: var(--color-2);
  border: none;
  border-radius: 5px;
  color: white;
  cursor: pointer;
  font-size: 1rem;
  font-weight: 500;
  height: 60%;
  letter-spacing: 1px;
  text-transform: uppercase;
  transition: 0.2s;
  width: 100px;
}

.navbar-logout:hover {

  /* filter: grayscale(0.4); */
  scale: 0.95;
}
