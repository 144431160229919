.page-order-card {
  display: grid;
  gap: 20px;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  margin: auto;
  padding: 30px 10px;
  width: 85%;
}

.order-card-container {
  align-items: center;
  display: flex;
  justify-content: center;
}

.order-card {
  align-items: center;
  background-color: white;
  border: 1px solid #e1e5eb;
  border-radius: 6px;
  box-shadow: 0 1px 2px rgb(0 0 0 / 6%) , 0 1px 3px rgb(0 0 0 / 10%);
  cursor: pointer;
  display: flex;
  flex-direction: column;
  height: 150px;
  margin: 10px;
  padding: 0;
  transition: 0.3s;
  width: 220px;
}

.order-card .index {
  background-color: var(--color-1);
  border-radius: 6px 6px 0 0;
  color: white;
  font-size: 1rem;
  font-weight: 900;
  padding: 5px;
  width: 100%;
}

.order-card-inner {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-evenly;
  width: 100%;
}

.order-card:hover {
  scale: 1.1;
}

.card-status {
  background-color: var(--color-1);
  border-top: 1px solid;
  font-weight: 600;
  padding: 5px;
  width: 100%;
}

.pendente {
  border-color: white;
  color: white;

}

.preparando {
  border-color: yellow;
  color: yellow;
}

.em-transito {
  border-color: #17d217;
  color: #17d217;
}

.entregue {
  border-color: var(--color-3);
  color: var(--color-3);
}
