.details-details {
  display: flex;
  flex-direction: column;
}

.detials-description {
  align-items: center;

  /* background-color: green; */
  display: flex;
  font-size: 1.3rem;
  font-weight: 200;
  justify-content: space-around;
  padding: 30px 10px;
}

.btn-entregue {
  background-color: var(--color-2);
  border: none;
  border-radius: 5px;
  color: white;
  cursor: pointer;
  font-weight: 600;
  letter-spacing: 1px;
  padding: 15px;
  transition: 0.3s;
}

.btn-entregue:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

.btn-entregue:enabled:hover {
  scale: 1.1;
}

.details-total {
  font-size: 1.5rem;
  padding: 30px;
  text-align: center;

}

.detials-btn-seller {
  display: flex;
  gap: 15px;
}
