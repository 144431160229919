.login-page {
  align-items: center;
  display: flex;
  gap: 60px;
  height: 100vh;
  justify-content: center;
  width: 100vw;
}

.login-page-logo {
  border-right: 1px solid black;
  height: 360px;
  padding-right: 60px;
}

.login-form {
  display: flex;
  flex-direction: column;
  gap: 20px;
  height: 360px;
  justify-content: center;
  padding: 10px;
  width: 300px;

}

.login-form label {
  display: flex;
  flex-direction: column;
  font-size: 1.2rem;
}

.login-form label input {
  background-color: transparent;
  border: none;
  border-bottom: 1px solid black;
  outline: none;
  padding: 10px 10px 5px;
  width: 100%;
}

.login-btn-container {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.login-btn {
  background-color: var(--color-1);
  border: none;
  border-radius: 5px;
  color: white;
  cursor: pointer;
  font-size: 1.2rem;
  height: 35px;
  transition: 0.3s;
  width: 40%;
}

.login-btn:disabled {
  cursor: not-allowed;
  opacity: 0.7;
}

.login-password-label {
  position: relative;
}

.register-btn {
  background-color: transparent;
  border: none;
  color: green;
}

.register-btn a {
  font-size: 1rem;
  text-decoration: none;
}

.register-btn a:hover {
  text-decoration: underline;
}

.toggle-password-btn {
  background-color: transparent;
  border: none;
  bottom: 0;
  cursor: pointer;
  height: 30px;
  position: absolute;
  right: 0;
  width: 30px;
}

.toggle-password-btn img {
  width: 100%;
}


.hidden {
  opacity: 0;
}

.login-error {

  /* background-color: red; */
  color: var(--color-2);
  height: 1rem;
  text-align: center;
}


@media only screen and ( max-width : 768px ) {

  /* For mobile phones: */
  .login-page-logo {
    display: none;
  }
}
