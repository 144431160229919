.register-page {
  align-items: center;
  display: flex;
  gap: 60px;
  height: 100vh;
  justify-content: center;
  width: 100vw;
}

.register-button {
  background-color: var(--color-1);
  border: none;
  border-radius: 5px;
  color: white;
  cursor: pointer;
  font-size: 1.2rem;
  height: 35px;
  transition: 0.3s;
  width: 50%;
}

.register-button:disabled {
  cursor: not-allowed;
  opacity: 0.7;
}
