:root {

  --color-1: #3f3d56;

  --color-2: #ff6584;

  --color-3: #67d0ff;

  --color-4: #000123;

  --font-1: 'Lato' , sans-serif;

  --font-2: 'Montserrat' , sans-serif;

  --font-3: 'Roboto' , sans-serif;

  --font-4: arial;

}

html {
  box-sizing: border-box;
  font-size: 16px;
}

*, *:before, *:after {
  box-sizing: inherit;
  font-family: var(--font-3);
}

body, h1, h2, h3, h4, h5, h6, p, ol, ul {
  font-weight: 400;
  margin: 0;
  padding: 0;
}

ol, ul {
  list-style: none;
}

img {
  height: auto;
  max-width: 100%;
}

body {
  background-color: #f5f5f5;
}


.loading {

  --r1: 154%;

  --r2: 68.5%;
  animation: p9 2s infinite linear;
  background: radial-gradient(var(--r1) var(--r2) at top, #0000 79.5%, var(--color-3) 80%) , radial-gradient(var(--r1) var(--r2) at bottom, var(--color-3) 79.5%, #0000 80%) , radial-gradient(var(--r1) var(--r2) at top, #0000 79.5%, var(--color-3) 80%) , #ccc;
  background-position: -100% 0% , 0% 0% , 100% 0%;
  background-repeat: no-repeat;
  background-size: 50.5% 220%;
  border-radius: 50%;
  height: 120px;
  left: 50%;

  /* margin: auto; */
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 120px;
}

@keyframes p9 {

  33% { background-position: 0% 33% , 100% 33% , 200% 33%; }

  66% { background-position: -100%  66% , 0%   66% , 100% 66%; }

  100% { background-position: 0% 100% , 100% 100% , 200% 100%;}
}
