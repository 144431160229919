.admin-form {
  align-items: center;
  display: flex;
  gap: 80px;
  justify-content: center;
  padding-top: 30px;
}

.admin-page h3 {
  font-size: 1.7rem;
  font-weight: 200;
  padding: 30px 20px 10px;
  text-align: center;
}

.admin-form label {
  display: flex;
  flex-direction: column;
}

.admin-form label input {
  background-color: transparent;
  border: none;
  border-bottom: 1px solid black;
  margin-top: 18px;
  outline: none;
  padding: 10px 10px 5px;
  width: 100%;
}

.admin-form label select {
  border: 1px solid black;
  margin-top: 10px;
  outline: none;
  padding: 10px;
  text-align: center;
  width: 100%;
}


.btn-cadastrar {
  background-color: var(--color-2);
  border: none;
  border-radius: 5px;
  color: white;
  cursor: pointer;
  font-weight: 600;
  letter-spacing: 1px;
  padding: 15px;
  transition: 0.3s;
}

.btn-cadastrar:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

.btn-cadastrar:enabled:hover {
  scale: 1.1;
}

.admin-users-container {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 30px;

}

/* .user-card {
  align-items: center;
  border: 1px solid green;
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 200px;
} */

.user-card {
  align-items: center;
  background-color: white;
  border: 1px solid #e1e5eb;
  border-radius: 6px;
  box-shadow: 0 1px 2px rgb(0 0 0 / 6%) , 0 1px 3px rgb(0 0 0 / 10%);
  display: flex;
  flex-direction: column;
  gap: 15px;
  height: 190px;
  margin: 10px;
  padding: 0;
  position: relative;
  transition: 0.3s;
  width: 220px;
}

.user-card .index {
  background-color: var(--color-1);
  border-radius: 6px 6px 0 0;
  color: white;
  font-size: 1rem;
  font-weight: 900;
  padding: 5px;
  text-align: center;
  width: 100%;
}

.user-card p {
  max-width: 23ch;
  overflow: hidden;
  text-align: center;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.user-btn-remove {
  background-color: var(--color-2);
  border: none;
  border-radius: 0 0 5px 5px;
  bottom: 0;
  color: white;
  cursor: pointer;
  font-weight: 600;
  letter-spacing: 1px;
  padding: 10px;
  position: absolute;
  transition: 0.3s;
  width: 100%;
}

.user-btn-remove:hover {
  letter-spacing: 2px;

  /* box-shadow: 0 -2px 5px var(--color-2); */

  /* padding-top: 12px; */
}
